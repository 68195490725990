<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    :formatted-columns="formattedColumns"
    sidebar-size="xl"
    authority="Users_Badges"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../storeModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'badges',
        endpoint: 'badges',
        route: 'badges',
        title: {
          single: this.$i18n.t('Badge'),
          plural: this.$i18n.t('Badges'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'image', label: this.$t('Image'), sortable: false },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'subtitle', label: this.$t('subtitle'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [{ key: 'image', type: 'logo' }]
    },
    formFields() {
      return [
        {
          id: 'image',
          type: 'cropupload',
          label: `${this.$t('Image')} (100x100)`,
          width: 100,
          height: 100,
          autoupload: true,
          required: true,
          imgFormat: 'png',
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: 'max:20',
        },
        {
          id: 'subtitle',
          type: 'textarea',
          label: this.$t('subtitle'),
          required: true,
          rules: 'max:80',
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
        },
      ]
    },
  },
}
</script>
